import parse, {
  type DOMNode,
  domToReact,
  Element,
  type HTMLReactParserOptions,
} from 'html-react-parser'
import { type ForwardedRef, forwardRef } from 'react'
import type { TextProps } from 'react-aria-components'
import { Text as AriaText } from 'react-aria-components'

import { textVariants } from '@/components/ui/elements/variants/text-variants'
import type { TextVariants } from '@/types/style-variants-types'

export const TextAsDefaultType = 'p' as const
export type TextAsDefaultType = typeof TextAsDefaultType

export interface TextComponentProps<T extends TextProps['elementType']>
  extends TextProps,
    TextVariants {
  elementType?: T
  withParser?: boolean
  insideProse?: boolean
}

function replaceChildren(html: string) {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if ((domNode as Element).name === 'p') {
        return <>{domToReact((domNode as Element).children as DOMNode[])}</>
      }
    },
  }
  return parse(html, options)
}

function TextComponent<T extends TextProps['elementType'] = TextAsDefaultType>(
  {
    intent,
    colorScheme,
    size,
    className,
    elementType = 'p',
    insideProse = false,
    withParser = false,
    ...props
  }: TextComponentProps<T>,
  ref: ForwardedRef<HTMLElement>,
) {
  if (!insideProse) {
    intent = intent ?? 'secondary'
    colorScheme = colorScheme ?? 'secondary'
    size = size ?? 'xs'
  }
  if (withParser) {
    props.children = replaceChildren(`${props.children}`)
  }
  return (
    <AriaText
      {...props}
      ref={ref}
      className={textVariants({ intent, colorScheme, size, className })}
      elementType={elementType}
    />
  )
}

export const Text = forwardRef(TextComponent)
