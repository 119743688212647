import { type ReactNode } from 'react'
import { CookiesProvider } from 'react-cookie'
import { SkeletonTheme } from 'react-loading-skeleton'

import AppReduxProvider from '@/components/site-components/app-redux-provider'
import { AppScriptsProvider } from '@/components/site-components/app-scripts-provider'
import AppSuspenseProvider from '@/components/site-components/app-suspense-provider'

export function AppProvider({ children }: { children: ReactNode }) {
  return (
    <AppScriptsProvider>
      <AppSuspenseProvider>
        <AppReduxProvider>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <SkeletonTheme baseColor="#1e293b" highlightColor="#64748b">
              {children}
            </SkeletonTheme>
          </CookiesProvider>
        </AppReduxProvider>
      </AppSuspenseProvider>
    </AppScriptsProvider>
  )
}
