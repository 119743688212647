exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-novosti-[slug]-tsx": () => import("./../../../src/pages/novosti/[slug].tsx" /* webpackChunkName: "component---src-pages-novosti-[slug]-tsx" */),
  "component---src-pages-pravni-resursi-legal-json-slug-tsx": () => import("./../../../src/pages/pravni-resursi/{legalJson.slug}.tsx" /* webpackChunkName: "component---src-pages-pravni-resursi-legal-json-slug-tsx" */),
  "component---src-pages-realizovani-projekti-tsx": () => import("./../../../src/pages/realizovani-projekti.tsx" /* webpackChunkName: "component---src-pages-realizovani-projekti-tsx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-innovations-mailboxes-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/innovations/mailboxes/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-innovations-mailboxes-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-innovations-other-products-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/innovations/other-products/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-innovations-other-products-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-machine-assemblies-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/machine-products/machine-assemblies/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-machine-assemblies-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-machining-operations-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/machine-products/machining-operations/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-machining-operations-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-sheet-metal-products-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/machine-products/sheet-metal-products/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-machine-products-sheet-metal-products-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-awnings-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/awnings/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-awnings-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-balconies-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/balconies/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-balconies-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-doors-and-windows-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/doors-and-windows/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-doors-and-windows-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-facades-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/facades/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-facades-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-fencing-systems-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/fencing-systems/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-fencing-systems-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-security-systems-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/security-systems/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-security-systems-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-stairs-and-ladders-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/stairs-and-ladders/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-stairs-and-ladders-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-steel-constructions-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/steel-constructions/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-steel-constructions-index-mdx" */),
  "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-winter-gardens-and-skylights-index-mdx": () => import("./../../../src/templates/category-template.tsx?__contentFilePath=F:/projects/decker-bih/content/categories/metal-constructions/winter-gardens-and-skylights/index.mdx" /* webpackChunkName: "component---src-templates-category-template-tsx-content-file-path-content-categories-metal-constructions-winter-gardens-and-skylights-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-nasi-partneri-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/bm-decker/nasi-partneri/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-nasi-partneri-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-o-nama-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/bm-decker/o-nama/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-o-nama-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-posao-i-karijera-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/bm-decker/posao-i-karijera/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-bm-decker-posao-i-karijera-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-products-innovations-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/products/innovations/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-products-innovations-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-products-machine-products-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/products/machine-products/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-products-machine-products-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-content-pages-products-metal-construction-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=F:/projects/decker-bih/content/pages/products/metal-construction/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-content-pages-products-metal-construction-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-assembly-installation-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/assembly-installation/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-assembly-installation-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-cutting-technologies-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/cutting-technologies/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-cutting-technologies-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-deformation-processing-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/deformation-processing/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-deformation-processing-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-engineering-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/engineering/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-engineering-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-machining-processing-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/machining-processing/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-machining-processing-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-surface-protection-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/surface-protection/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-surface-protection-index-mdx" */),
  "component---src-templates-services-template-tsx-content-file-path-content-services-welding-index-mdx": () => import("./../../../src/templates/services-template.tsx?__contentFilePath=F:/projects/decker-bih/content/services/welding/index.mdx" /* webpackChunkName: "component---src-templates-services-template-tsx-content-file-path-content-services-welding-index-mdx" */),
  "slice---src-components-ui-content-footer-main-footer-tsx": () => import("./../../../src/components/ui/content/footer/main-footer.tsx" /* webpackChunkName: "slice---src-components-ui-content-footer-main-footer-tsx" */),
  "slice---src-components-ui-content-navbar-main-navbar-tsx": () => import("./../../../src/components/ui/content/navbar/main-navbar.tsx" /* webpackChunkName: "slice---src-components-ui-content-navbar-main-navbar-tsx" */),
  "slice---src-components-ui-content-sidebar-items-tsx": () => import("./../../../src/components/ui/content/sidebar-items.tsx" /* webpackChunkName: "slice---src-components-ui-content-sidebar-items-tsx" */)
}

