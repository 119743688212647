import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMemo } from 'react'
import { Switch } from 'react-aria-components'

import { Heading } from '@/components/ui/elements/heading'
import { ListBox, ListBoxItem } from '@/components/ui/elements/list-box'
import { Text } from '@/components/ui/elements/text'

export function BannerSettings() {
  const { t } = useTranslation('common', { keyPrefix: 'banner.sections' })

  const items = useMemo(
    () => [
      {
        id: 'analytics',
        label: t('analytics.title'),
        description: t('analytics.description'),
      },
      {
        id: 'ads',
        label: t('ads.title'),
        description: t('ads.description'),
      },
    ],
    [t],
  )

  return (
    <div className={'relative flex flex-col items-start'}>
      <Heading level={3}>{t('main.title')}</Heading>
      <Text className={'mt-1'}>{t('main.description')}</Text>
      <ListBox
        items={items}
        aria-label={'consent settings'}
        selectionMode={'multiple'}
        className={'w-full text-balance bg-slate-100 dark:bg-slate-950'}
      >
        {(item) => (
          <ListBoxItem
            textValue={item.label}
            className={'mb-2 flex justify-between p-2'}
          >
            {({ isSelected }) => (
              <>
                <Text
                  elementType={'p'}
                  size={'small'}
                  intent={'primary'}
                  slot="label"
                  className={'m-0'}
                >
                  {item.label}
                </Text>

                <Switch
                  className={
                    'group flex items-center gap-2 text-lg font-semibold text-black'
                  }
                  aria-label={'selection'}
                  isSelected={isSelected}
                >
                  <div className="box-border flex h-[26px] w-[44px] shrink-0 cursor-default rounded-full border border-solid border-white/30 bg-brand-600 bg-clip-padding p-[3px] shadow-inner outline-none ring-black transition duration-200 ease-in-out group-focus-visible:ring-2 group-pressed:bg-brand-700 group-selected:bg-brand-800 group-selected:group-pressed:bg-brand-900">
                    <span className="h-[18px] w-[18px] translate-x-0 transform rounded-full bg-white shadow transition duration-200 ease-in-out group-selected:translate-x-[100%]" />
                  </div>
                </Switch>
              </>
            )}
          </ListBoxItem>
        )}
      </ListBox>
    </div>
  )
}
