/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useCallback, useEffect, useState } from 'react'
import type { Selection } from 'react-aria-components'
import { ListBoxContext } from 'react-aria-components'
import { useCookies } from 'react-cookie'

import { BannerSettings } from '@/components/ui/content/cookie-banner/banner-settings'
import { Button } from '@/components/ui/elements/button'
import { Prose } from '@/components/ui/elements/prose'
import { MdxTransLink } from '@/components/ui/section-map/mdx-trans-components'
import { ButtonType } from '@/types/style-variants-types'

export type ConsentOutCome = 'denied' | 'granted'

export type GtagConsent = {
  ad_storage: ConsentOutCome
  ad_user_data: ConsentOutCome
  ad_personalization: ConsentOutCome
  analytics_storage: ConsentOutCome
}

const defaultConsent = {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
} as GtagConsent

export function CookieBanner() {
  const { t } = useTranslation('common', { keyPrefix: 'banner' })
  const [decisionMade, setDecisionMade] = useState(true)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [consentState, setConsentState] = useState<Selection>(new Set([]))

  const [cookies, setCookie] = useCookies(['decker-consent'], {
    doNotUpdate: true,
  })

  const sendConsent = useCallback((consent: GtagConsent) => {
    if ('gtag' in window) {
      // @ts-ignore
      window.gtag('consent', 'update', consent)
    }
  }, [])

  useEffect(() => {
    if (Object.hasOwn(cookies, 'decker-consent')) {
      setDecisionMade(true)
    } else {
      setDecisionMade(false)
    }
  }, [cookies])

  const handleDenied = () => {
    setCookie('decker-consent', defaultConsent, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: '/',
    })

    sendConsent(defaultConsent)
    setDecisionMade(true)
  }

  const handleDecision = (outcome?: ConsentOutCome) => {
    let consent: GtagConsent = defaultConsent
    if (consentState === 'all' || outcome === 'granted') {
      consent = {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
      }
    } else {
      consent = {
        ad_storage: consentState.has('ads') ? 'granted' : 'denied',
        ad_user_data: consentState.has('ads') ? 'granted' : 'denied',
        ad_personalization: consentState.has('ads') ? 'granted' : 'denied',
        analytics_storage: consentState.has('analytics') ? 'granted' : 'denied',
      }
    }
    setCookie('decker-consent', consent, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: '/',
    })

    sendConsent(consent)
    setDecisionMade(true)
  }
  if (decisionMade) {
    return null
  }
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
      <Prose
        withElements
        links
        className="pointer-events-auto text-center sm:text-left max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10 md:rounded-2xl dark:bg-slate-950 dark:shadow-none dark:ring-white/10"
      >
        <p>
          {t('description')}{' '}
          <MdxTransLink transKey={'banner.sections.info.description'} />
        </p>
        {settingsOpen && (
          <ListBoxContext.Provider
            value={{
              selectedKeys: consentState,
              onSelectionChange: setConsentState,
            }}
          >
            <BannerSettings />
          </ListBoxContext.Provider>
        )}
        <div className="mt-4 flex flex-col items-center gap-y-2">
          <div className={'flex items-center gap-x-5'}>
            <Button
              backgroundColor={'primary'}
              onPress={() => handleDecision('granted')}
            >
              {t('acceptAllBtn')}
            </Button>
            <Button backgroundColor={'brand'} onPress={() => handleDenied()}>
              {t('acceptNecessaryBtn')}
            </Button>
          </div>
          <div className={'flex items-center gap-x-5'}>
            <Button
              buttonType={ButtonType.PLAIN}
              className={'p-0'}
              onPress={() => setSettingsOpen(!settingsOpen)}
            >
              {t('showPreferencesBtn')}
            </Button>

            {consentState === 'all' ||
              ([...consentState].length > 0 && (
                <Button
                  className={'p-0'}
                  buttonType={ButtonType.PLAIN}
                  onPress={() => handleDecision()}
                >
                  {t('acceptAllSelected')}
                </Button>
              ))}
          </div>
        </div>
      </Prose>
    </div>
  )
}
