/* eslint-disable no-undef */

export const gtagInit = `
function decode(e){try{return decodeURIComponent(e)}catch{return e}}window.dataLayer=window.dataLayer||[],window.gtag=function(){window.dataLayer.push(arguments)};const obj={},cookies=window?.document.cookie,list=cookies.split(";");for(const e of list){if(""===e)continue;const o=e.indexOf("="),n=(o>0?e.slice(0,o):e).trim(),t=o>0?decode(e.slice(o+1).trim()):void 0;n in obj||(obj[n]=t)}if("decker-consent"in obj){const e=JSON.parse(obj["decker-consent"]);gtag("consent","default",e)}else gtag("consent","default",{ad_storage:"denied",ad_user_data:"denied",ad_personalization:"denied",analytics_storage:"denied"});
`

export const tagConfig = `
 window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag(){ window.dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}', { send_page_view: false });
`
