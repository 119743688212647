import { Script } from 'gatsby'
import { Fragment, type ReactNode } from 'react'

import { gtagInit, tagConfig } from '@/lib/gtag-script'

export interface AppScriptsProviderProps {
  children: ReactNode
}

export const AppScriptsProvider = ({ children }: AppScriptsProviderProps) => {
  return (
    <Fragment>
      <Script
        id={'consent-config'}
        dangerouslySetInnerHTML={{ __html: gtagInit }}
      />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}
        id={'gtag'}
      />
      <Script
        id={'gtag-config'}
        dangerouslySetInnerHTML={{ __html: tagConfig }}
      />
      {children}
    </Fragment>
  )
}
