import parse from 'html-react-parser'
import type { ElementType, ReactNode } from 'react'
import { tv, type VariantProps } from 'tailwind-variants'

const prose = tv({
  base: 'prose prose-slate dark:prose-invert',
  variants: {
    headings: {
      true: 'prose-headings:font-semibold prose-headings:leading-relaxed prose-headings:tracking-tight prose-headings:text-slate-800 dark:prose-headings:text-slate-200',
    },
    paragraphs: {
      true: 'prose-p:font-medium prose-p:tracking-tight prose-p:text-slate-600 dark:prose-p:text-slate-400',
    },
    lead: {
      true: 'prose-lead:text-base prose-lead:font-medium prose-lead:text-slate-500 dark:prose-lead:text-slate-400',
    },
    size: {
      lg: 'prose-lg',
      xl: 'prose-xl',
    },
  },
  defaultVariants: {
    paragraphs: true,
  },
})

const proseWithElements = tv({
  extend: prose,
  variants: {
    links: {
      true: 'prose-a:font-semibold prose-a:text-brand-600 prose-a:no-underline hover:prose-a:underline dark:prose-a:text-brand-400',
    },
    image: {
      true: 'prose-img:transform prose-img:rounded-2xl prose-img:shadow-md prose-img:transition prose-img:will-change-auto hover:prose-img:brightness-110',
    },
  },
})

export interface ProseVariants extends VariantProps<typeof proseWithElements> {
  className?: string
  withElements?: boolean
}

export const ProseAsDefaultType = 'article' as const
export type ProseAsDefaultType = typeof ProseAsDefaultType

export interface ProseProps<T extends ElementType> extends ProseVariants {
  children?: ReactNode
  as?: T
  nodeStrings?: string[]
}

export const composeProse = ({ withElements, ...props }: ProseVariants) => {
  if (withElements) {
    return proseWithElements({ ...props })
  }
  return prose({ ...props })
}

export function Prose<T extends ElementType = ProseAsDefaultType>({
  as,
  nodeStrings,
  children,
  withElements,
  ...props
}: ProseProps<T>) {
  const Component = as || ProseAsDefaultType
  const nodes: Partial<Array<ReactNode>> = []
  if (nodeStrings) {
    nodeStrings.forEach((nodeString) => nodes.push(parse(nodeString)))
  }
  return (
    <Component className={composeProse({ withElements, ...props })}>
      {nodeStrings?.length ? nodes : children}
    </Component>
  )
}
