import { CheckIcon } from '@heroicons/react/16/solid'
import {
  composeRenderProps,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  type ListBoxItemProps as AriaListBoxItemProps,
  type ListBoxProps as AriaListBoxProps,
  Switch,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { borderRing } from '@/components/ui/elements/variants/border-variants'
import { textVariants } from '@/components/ui/elements/variants/text-variants'
import { composeTailwindRenderProps } from '@/lib/helpers'
import type { TextVariants } from '@/types/style-variants-types'

interface ListBoxProps<T> extends AriaListBoxProps<T> {
  variant?: 'primary' | 'grid'
}

const listBox = tv({
  base: 'p-1 outline-none',
  variants: {
    variant: {
      primary:
        'max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)] focus:outline-none',
      grid: 'mx-auto grid max-w-lg grid-cols-2 items-center gap-4 sm:max-w-xl sm:grid-cols-3 sm:gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export function ListBox<T extends object>({
  children,
  ...props
}: ListBoxProps<T>) {
  return (
    <AriaListBox
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        listBox({ variant: props.variant }),
      )}
    >
      {children}
    </AriaListBox>
  )
}

export const itemStyle = tv({
  base: 'relative rounded-xl outline-none',
  extend: textVariants,
  variants: {
    isFocusVisible: {
      true: borderRing.brandRing,
    },
    isSelected: {
      true: 'bg-brand-100 dark:bg-brand-700/30',
    },
    isDisabled: {
      true: 'text-slate-300 dark:text-zinc-600 forced-colors:text-[GrayText]',
    },
    borderRing: {
      true: borderRing.ring,
    },
    hoverStyle: {
      true: borderRing.hoverRing,
    },
  },
})

export interface ListBoxItemProps extends AriaListBoxItemProps {
  colorScheme?: TextVariants['colorScheme']
  size?: TextVariants['size']
  borderRing?: boolean
  hoverStyle?: boolean
  useSwitch?: boolean
}

export function ListBoxItem({
  colorScheme,
  size,
  borderRing,
  hoverStyle,
  ...props
}: ListBoxItemProps) {
  return (
    <AriaListBoxItem
      {...props}
      className={composeRenderProps(props.className, (prevValue, renderProps) =>
        itemStyle({
          ...renderProps,
          colorScheme,
          size,
          borderRing,
          hoverStyle,
          className: prevValue,
        }),
      )}
    />
  )
}

export const dropdownItemStyles = tv({
  base: 'group flex cursor-default select-none items-center gap-4 rounded-lg py-2 pl-3 pr-1 text-sm outline outline-0 forced-color-adjust-none',
  variants: {
    isDisabled: {
      false: 'text-slate-900 dark:text-slate-100',
      true: 'text-slate-300 dark:text-slate-600 forced-colors:text-[GrayText]',
    },
    isFocused: {
      true: 'bg-slate-100 text-red-600 dark:bg-slate-950/80 dark:text-red-400',
    },
  },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: 'bg-slate-100 dark:bg-slate-700/60',
    },
  ],
})

export function DropdownItem(props: ListBoxItemProps) {
  const textValue =
    props.textValue ||
    (typeof props.children === 'string' ? props.children : undefined)
  return (
    <AriaListBoxItem
      {...props}
      textValue={textValue}
      className={dropdownItemStyles}
    >
      {composeRenderProps(
        props.children,
        (children, { isSelected, selectionMode }) => (
          <>
            <span className="flex flex-1 items-center gap-2 truncate font-normal group-selected:font-semibold">
              {children}
            </span>
            {selectionMode !== 'none' && props.useSwitch ? (
              <Switch isSelected={isSelected} aria-label={'selection'} />
            ) : (
              <span className="flex w-5 items-center">
                {isSelected && <CheckIcon className="h-4 w-4" />}
              </span>
            )}
          </>
        ),
      )}
    </AriaListBoxItem>
  )
}
