module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BM Decker","short_name":"BMD","description":"Decker Braća Mujić d.o.o. počeo je sa radom 2012. godine i za veoma kratko vrijeme postao prepoznatljiv partner za proizvodnju metalnih konstrukcija i njihovih dijelova","lang":"bs","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"none","start_url":"/","background_color":"#030712","theme_color":"#09090b","categories":["business","metal industry","mechanical engineering"],"screenshots":[{"src":"/decker-manifest-narrow-1.png","type":"image/png","sizes":"540x720"},{"src":"/decker-manifest-narrow.jpg","type":"image/jpeg","sizes":"540x720"},{"src":"/decker-manifest-wide-1.png","type":"image/png","sizes":"1024x450"},{"src":"/decker-manifest-wide.jpg","type":"image/jpeg","sizes":"1024x450"}],"localize":[{"start_url":"/en/","lang":"en","name":"BM Decker","short_name":"BMD","description":"Decker BM is a subsidiary of the company BRAĆA MUJIĆ EXPORT-IMPORT d.o.o., which began operations at the end of 2012, with its headquartes in Ilidža. In a very short period it became a recognizable partner in the metal industry."},{"start_url":"/fr/","lang":"fr","name":"BM Decker","short_name":"BMD","description":"Filiale Decker BM de la société BRAĆA MUJIĆ EXPORT-IMPORT d.o.o. qui a commencé à travailler fin 2012 avec son siège social à Ilidža en Bosnie-Herzégovine et est devenue en très peu de temps un partenaire reconnaissable dans l'industrie métallurgique."}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["bs","en","fr"],"defaultLanguage":"bs","siteUrl":"https://bm-decker.ba","redirect":false,"i18nextOptions":{"preload":["bs","en","fr"],"lowerCaseLng":true,"interpolation":{"escapeValue":false},"nsSeparator":false,"react":{"bindI18n":"languageChanged","useSuspense":true,"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i","p","h1","h2","h3","h4","h5","h6","ul","li"]}},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/dev-404-page","languages":["bs"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#b91c1c","showSpinner":true},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"bm-decker-664544653.imgix.net","secureURLToken":"AZ8bACrvc2QNTE7y","sourceType":"webFolder","defaultImgixParams":{"auto":"format,compress"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
