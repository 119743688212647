import './src/styles/global.css'
import '@fontsource-variable/montserrat'
import 'react-loading-skeleton/dist/skeleton.css'

import type { GatsbyBrowser } from 'gatsby'

import { wrapWithLayout } from './wrapWithLayout'
import { wrapWithProvider } from './wrapWithProvider'

export const wrapPageElement = wrapWithLayout

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] =
  wrapWithProvider

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  const lang = document?.documentElement.lang
  const currentLang = window?.localStorage.getItem('gatsby-i18next-language')
  if (currentLang && currentLang !== lang) {
    document.documentElement.lang = currentLang
  }

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)

  return true
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
}) => {
  if (location.state && Object.hasOwn(location.state, 'updateScroll')) {
    return location.state.updateScroll
  }
  return true
}
