import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import parse from 'html-react-parser'
import { useMemo } from 'react'
import { Link } from 'react-aria-components'

import { range } from '@/lib/helpers'

export interface MdxListProps {
  transKey: string
  namespace?: string
  keyPrefix?: string
  nestedList?: boolean
}

export function MdxTransList({
  transKey,
  namespace,
  keyPrefix,
  nestedList,
}: MdxListProps) {
  const { t } = useTranslation(namespace, { keyPrefix })

  const list = useMemo(
    () => t(transKey, { returnObjects: true }),
    [t, transKey],
  )

  if (nestedList) {
    return (
      <ul>
        {Object.entries(list).map(([key, value]) =>
          key.includes('heading') ? (
            <p className={'lead'} key={key}>
              {value}
            </p>
          ) : (
            Object.entries<string>(value).map(([listKey, listItem]) => (
              <li key={listKey}>{parse(listItem)}</li>
            ))
          ),
        )}
      </ul>
    )
  }
  return (
    <ul>
      {Object.values(list).map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  )
}

export interface IMdxTransLinkProps {
  transKey: string
  namespace?: string
}

export function MdxTransLink({ transKey, namespace }: IMdxTransLinkProps) {
  return (
    <Trans
      i18nKey={transKey}
      namespace={namespace}
      components={{ TransLink: <Link /> }}
    />
  )
}

export interface IMdxRangeParagraphProps {
  nmItems: number
  transKey: string
}

export function MdxRangeParagraph({
  nmItems,
  transKey,
}: IMdxRangeParagraphProps) {
  return range(nmItems).map((i) => (
    <Trans transKey={`${transKey}-${i}`} key={i} />
  ))
}

export function MdxTransUnorderedList({
  nmItems,
  transKey,
}: IMdxRangeParagraphProps) {
  return (
    <ul>
      {range(nmItems).map((i) => (
        <li key={`${transKey}-${i}`}>
          <Trans i18nKey={`${transKey}-${i}`} />
        </li>
      ))}
    </ul>
  )
}
